import React from "react";
import { categories } from "../../../config";
import PropTypes from "prop-types";
import { Link } from "gatsby";

class Category extends React.Component {
    render() {
        let margin = '-16px';
        if (this.props.status === 1) margin = '20px'
        return (
            <div className="Categories_container" style={{marginTop:margin}}>
                {
                    this.props.status === 2?
                    <div className="category-description">
                        <img src="/assets/bannerOurProducts-ww1200.jpg"/>
                        <div className="col col-lg-6 col-md-7 col-sm-8 col-xs-9 product-category-description">
                            <div className="category-title">
                                {'Our Products and Services'}
                            </div>
                            <div className="category-content">
                                {'We are one-stop supplier for Lithium-ion battery research related equipment and consumables. We also offer design and customization service for specific research needs, including engineering design service for IoT solutions in Smart City and Industrial automation. All inquiries are welcome. Please email us: sales@x2lab.com.sg, and we will send you a reply shortly.'}
                            </div>
                        </div>
                    </div>
                    :
                    <div></div>
                }
                {
                    this.props.status === 2 ?
                    <div className="category_container">
                        {
                            this.props.item.map((item, key) => {
                                return (
                                    <div className="each_category" key={key}>
                                        <Link
                                            to={`/products?category_${item.title}`}
                                            className="news-item"
                                            >
                                            <div className="image_container">
                                                <img src={item.mainimage} />
                                            </div>
                                        </Link>
                                        <div className="news-title-container">
                                            <h2 className="news-title gotham-font">{item.title}</h2>
                                            <div className="news-subtitle">
                                                <span>{item.detail}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div className="category_container status_1">
                        {
                            this.props.item.map((item, key) => {
                                return (
                                    <div className="each_category" key={key}>
                                        <div className="products col-12 col-xs-12 col-sm-12 col-md-12 col-lg-7">
                                            <Link
                                                to={`/products?category_${item.title}`}
                                                className="news-item"
                                                >
                                                <div className="image_container">
                                                    <img src={item.mainimage} />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="products col-12 col-xs-12 col-sm-12 col-md-12 col-lg-5">
                                            <div className="news-title-container">
                                                <h2 className="news-title gotham-font">{item.title}</h2>
                                                <div className="news-subtitle">
                                                    <span>{item.detail}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                
                <style jsx>{`
                    .status_1 {
                        .each_category {
                            width: 100% !important;
                            display: flex;
                            margin-bottom: 40px !important;
                            .image_container {
                                width: 100%;
                                overflow: hidden;
                                height: 300px !important;
                                transition: all 1.5s;
                                img {
                                    transition: all 1.5s;
                                    width: 100%;
                                    height: 300px !important;
                                    object-fit: cover;
                                    :hover {
                                        transform: scale(1.2);
                                    }
                                }
                            }
                        }
                    }
                    .product-category-description {
                        margin: 0 auto;
                        margin-top: 30px;
                        margin-bottom: 30px;
                    }
                    .category-description {
                        margin-bottom: 15px !important;
                        padding: 20px 20px;
                        padding: 0px !important;
                        margin: 0 auto;
                        img {
                            width: 100%;
                            height: 360px;
                            object-fit: cover;
                        }
                        .category-title {
                            color: #0098e4;
                            font-size: 24px;
                            text-transform: uppercase;
                            font-weight: 600;
                        }
                        .category-content {
                        color: #676767;
                        font-weight: 600;
                        font-size: 16px;
                        }
                    }
                    .news-title-container {
                        padding-left: 5px !important;
                        padding: 18px;
                        display: flex;
                        flex-direction: column;
                        align-items: left;
                        h2 {
                            color: #676767;
                            font-size: 24px;
                            text-align: left;
                            margin-bottom: 10px;
                            text-transform: uppercase;
                        }
                        .news-subtitle {
                            text-align: left;
                            span {
                                color: #676767;
                                font-weight: 600;
                                font-size: 15px;
                            }
                        }
                        .news-title {
                        font-size: 20px;
                        }
                    }
                    .Categories_container {
                        margin: 0 auto;
                        margin-top: -16px;
                    }
                    .category_container {
                        max-width: 65em;
                        margin-left: auto;
                        margin-right: auto;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        .each_category {
                            width: 45%;
                            margin-bottom: 15px;
                            overflow: hidden;
                            .image_container {
                                width: 100%;
                                overflow: hidden;
                                height: 300px;
                                transition: all 1.5s;
                                img {
                                    transition: all 1.5s;
                                    width: 100%;
                                    height: 300px;
                                    object-fit: cover;
                                    :hover {
                                        transform: scale(1.2);
                                    }
                                }
                            }
                        }
                    }
                    @below desktop {
                        .status_1 {
                            .each_category {
                                display: block !important
                            }
                        }
                    }
                    @below tablet {
                        .products {
                            padding-left: 0px;
                            padding-right: 0px;
                        }
                        .status_1 {
                            .each_category {
                                width: 100% !important;
                                display: block !important;
                            }
                        }
                        .category-description { 
                            img {
                                height: 200px;
                            }
                        }
                        .category_container {
                            justify-content: center;
                            .each_category {
                                width: 100% !important;
                                .image_container {
                                    width: 100%;
                                    overflow: hidden;
                                    height: 200px !important;
                                    transition: all 1.5s;
                                    img {
                                        transition: all 1.5s;
                                        width: 100%;
                                        height: 200px !important;
                                        object-fit: cover;
                                        :hover {
                                            transform: scale(1.2);
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}</style>
            </div>

        )
    }
}

export default Category;