import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      router: 0
    };
  }
  render() {
    let item = this.props.item;
    let imgHeight = this.props.imgHeight;
    return (
      <div className="col col-lg-4 col-md-6 col-sm-6 col-sm-12 col-12">
        <Link
          to={`/productdetail?${item.name}`}
          className="news-item"
        >
          <div className="header-container">
            <img src={item.mainimage} />
          </div>
          <div className="news-title-container">
            <h2 className="news-title gotham-font">{item.title}</h2>
            <div className="news-subtitle">
              <span>{item.subtitle}</span>
            </div>
          </div>
        </Link>
        {/* --- STYLES --- */}
        <style jsx>{`
          .col {
            padding: 15px;
          }
          .news-item {
            height: 100%;
            padding: 0 0 15px;
            display: flex;
            flex-direction: column;
          }
          .header-container {
            height: 220px;
            width: 100%;
            overflow: hidden;
            display: flex;
            position: relative;
            align-items: center;
            transition: all 5s;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
            img {
              transition: all 1.5s;
              width: 100%;
              display: block;
              margin: 0 auto;
              object-fit: contain !important;
              :hover {
                transform: scale(1.2);
              }
            }
          }
          .news-title-container {
            padding: 18px;
            display: flex;
            flex-direction: column;
            align-items: center;
            h2 {
              color: #676767;
              font-size: 22px;
              text-align: center;
              margin-bottom: 10px;
            }
            .news-subtitle {
              text-align: center;
              span {
                color: #676767;
                font-weight: 600;
                font-size: 13px;
              }
            }
            .news-title {
              font-size: 18px;
            }
          }
          @from-width desktop {
          }
          @below desktop {
            .news-title-container {
              padding: 5px !important;
              h2 {
                font-size: 17px;
              }
              .news-subtitle {
                font-size: 12px;
              }
            }
          }
        `}</style>
      </div>
    );
  }
}

Product.propTypes = {
  item: PropTypes.object,
  imgHeight: PropTypes.number,
  item_index: PropTypes.number,
  history: PropTypes.object,
  imageloadcount: PropTypes.func
};

export default Product;
