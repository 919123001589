import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { FadeLoader } from "react-spinners";
import { ThemeContext } from "../layouts";
import Categories from "../components/Category";
import Seo1 from "../components/Seo/Seo1"
import Category from "../components/Category/EachCategory"
import { categories } from "../../config";
import Product from "../components/Product";
class ProductPage extends React.Component {
  mounted = 0;
  constructor(props) {
    super(props);
    this.state = {
      state: 1,
      products: [],
      category: [],
      keywords: [],
      width: 0,
      height: 0,
      selectedcat: "all",
      imageloadfinished: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.SelectCategory = this.SelectCategory.bind(this);
    this.render_path = this.render_path.bind(this);
    this.render_category_description = this.render_category_description.bind(this);
  }

  render_category_description() {
    let cate = {}
    for ( let i = 0 ; i < this.state.category.length; i += 1 ) {
      if (this.state.category[i].title === this.state.selectedcat) {
        cate = this.state.category[i]
      }
    }
    return (
      <div className="category-description">
        <div className="a-container">
          <div className="category-title">
            {cate.title}
          </div>
          <div className="category-content">
            {cate.detail}
          </div>
        </div>
        <style jsx>{`
          .category-description {
            display:flex;

            .a-container {
              margin: auto;
              max-width: 800px;
            }
            text-align: left;
            max-width: 75em;
            padding: 20px 20px;
            margin: 0 auto;
            .category-title {
              color: #0098e4;
              font-size: 24px;
              text-transform: uppercase;
              font-weight: 600;
            }
            .category-content {
              color: #676767;
              font-weight: 600;
              font-size: 16px;
            }
          }
        `}</style>
      </div>
    )
  }

  render_path() {
    let param = this.props.location.search.split('?')
    var _self = this
    if(param.length >= 2) {
      let pa = param[1].split('_')
      if(pa[0] = 'category') {
        let cate = pa[1].split('%20').join(' ')
        if (this.state.selectedcat !== cate)
          //setTimeout(() => {
            _self.setState({
              selectedcat: cate
            })
          //},10)
      }
    } else {
      if (this.state.selectedcat !== 'all')
          //setTimeout(() => {
            _self.setState({
              selectedcat: 'all'
            })
          //},10)
    }
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  componentDidMount() {
    if (this.props.locaiton !== undefined && this.props.locaiton.state !== undefined && this.props.location.state.index !== undefined) {
      this.setState({
        selectedcat: categories[this.props.location.state.index]
      });
    }
    this.mounted = true;
    let products = [];
    let category = [];
    let keywords = [];
    let flag = [];
    var self = this;
    this.props.data.allMarkdownRemark.edges.map((item, key) => {
      const product = item.node.frontmatter;
      products.push(product);
    });

    this.props.data.category.edges.map((item,key) => {
      const ca = item.node.frontmatter;
      category.push(ca);
      flag.push(0);
      keywords.push(ca.title);
    })

    let Order = []
    let pos = 0;
    for ( let i = 0 ; i < category.length ; i ++) {
      let Min = 1000
      for ( let j = 0 ; j < category.length ; j ++) {
        if (flag[j]) continue
        if (Min > Number(category[j].order)) {
          Min = Number(category[j].order)
          pos = j
        }
      }
      flag[pos] = 1;
      Order.push(category[pos])
    }

    setTimeout(() => {
      if (self.mounted)
        self.setState({
          imageloadfinished: 1
        });
    }, 500);
    this.setState({
      products: products,
      category: Order,
      keywords: keywords
    });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  SelectCategory(key) {
    this.setState({
      //selectedcat: this.state.category[key].title
      // change key from title to name, name is used as URL, as always.
      // title will be shown as title      
      selectedcat: this.state.category[key].title
    });
  }
  render() {
    this.render_path();
    let imgHeight = Math.max(220, this.state.width * 0.12);
    imgHeight = Math.min(imgHeight, 300);
    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <div className="product_container">
              <Categories categories = {this.state.category} selectedcat={this.state.selectedcat} onClick={this.SelectCategory} />
              <div
                className={
                  this.state.imageloadfinished === 0 ? "flex-grid image-loading-spin" : "hidden"
                }
              >
                <FadeLoader className="spinner" />
                <p className="loadingproductdetail">Loading products...</p>
              </div>
              {
                this.state.selectedcat === "all" ?
                <div className={this.state.imageloadfinished === 1 ? "" : "hidden"}>
                  <Category
                    status={2}
                    item={this.state.category}
                  />
                </div>
                :
                <div className={this.state.imageloadfinished === 1 ? "" : "hidden"}>
                  {this.render_category_description()}
                </div>
              }
              <div className={this.state.imageloadfinished === 1 ? "products" : "hidden"}>
                
                {this.state.products.map((item, index) => {
                  if (this.state.selectedcat === item.category) {
                    return (
                      <Product
                        imageloadcount={this.loadcount}
                        item={item}
                        imgHeight={imgHeight}
                        key={index}
                        item_index={index}
                      />
                    );
                  }
                })}
              </div>
              <Seo1
                //postTitle = {this.state.selectedcat}
                postTitle = {this.state.selectedcat !== '' ? this.state.selectedcat : 'Category View'}
                postDescription = {'X2LAB Product List / Categories'}
                keywords = {this.state.keywords}
              />
              {/* --- STYLES --- */}
              <style jsx>{`
                .hidden {
                  display: none;
                }
                .image-loading-spin {
                  text-align: center;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  margin-top: 100px;
                }
                .products {
                  max-width: 80em;
                  margin: 0 auto;
                  padding: 0 20px;
                  padding-top: 30px;
                  display: flex;
                  flex-wrap: wrap;
                }
                .product_container {
                  padding: 0rem;
                  margin: 0 auto;
                }
                @from-width desktop {
                  .product_container {
                   
                  }
                }
                @below tablet {
                  .products {
                    padding: 0 5px;
                  }
                }
              `}</style>
            </div>
          )}
        </ThemeContext.Consumer>
      </React.Fragment>
    );
  }
}

ProductPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object
};

export default ProductPage;

/*eslint-disable */
export const pageQuery = graphql`
  query ProductQuery {
    category: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "category-item"}}}) {
      edges {
        node {
          id
          frontmatter {
            title
            detail
            mainimage
            order
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "store-item" } }}
      sort: {order:ASC, fields:fields___slug}
    ) {
      edges {
        node {
          id
          fields {
            slug
          }          
          frontmatter {
            title
            name
            subtitle
            category
            price
            detail
            mainimage
            dimension
            rating
            subimage {
              image
            }
          }
        }
      }
    }
  }
`
